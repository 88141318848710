<template>
  <div class="row">
    <div class="col-md-12"  v-if="Object.keys(admin).length !== 0">
      <div class="card">
          <div class="card-body">
          <p>Total Hubs: {{ admin_hubs.length }}</p>
          <h5 v-for="(admin_hub, index) in admin_hubs" :key="index">
            {{ index + 1 }}: {{ admin_hub.name }}
          </h5>
          <a-skeleton active :loading="loading"></a-skeleton>
        </div>
      </div>
      <a-button type="danger" class="text-center mr-2" @click="$router.go(-1)">Back</a-button>
    </div>
    <div class="col-md-12" v-else>
      <div class="card">
        <div class="card-body">
          <h5 class="text-center text-secondary">{{ message }}</h5>
        </div>
      </div>
      <a-button type="danger" class="text-center mr-2" @click="$router.go(-1)">Back</a-button>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'admin_hub_view',
  data() {
    return {
      admin: {},
      admin_hubs: [],
      show: false,
      message: null,
      loading: false,
      spinning: false,
      delayTime: 500,
    }
  },
  mounted() {
    this.getAdminHubs()
  },
  methods: {
    getAdminHubs() {
      const userId = this.$route.params.user_id
      this.spinning = true
      this.loading = true
      apiClient.get('api/admin-scenario/admin-hubs/' + userId)
        .then((response) => {
          this.spinning = false
          this.loading = false
          if (response.data.error === true) {
            this.message = response.data.message
          } else {
            this.admin = response.data.admin
            this.admin_hubs = response.data.admin.admin_hubs
            this.hubs = response.data.hubs
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
</style>
